import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import testimonials from '../assets/images/testimonials.jpeg';

const Testimonials = props => (
  <Layout>
    <Helmet>
      <title>Testimonials</title>
      <meta name="description" content="Testimonials" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Testimonials</h1>
          </header>
          <span className="image main">
            <img src={testimonials} alt="Testimonials" />
          </span>
          <p>
            <i>
              I have been training with Karmen for the past year. I'm a
              life-long runner, but at age 65, with a recent hip replacement, I
              feel stronger than I ever have thanks to her guidance. I
              appreciate Karmen's extensive knowledge of exercise science,
              passion to encourage, and focus on my individual needs, goals and
              capabilities. She has pushed me hard, yet training injury-free to
              achieve maximum results, she has fostered habits that will keep me
              aging healthy and strong. I am confident that I will accomplish my
              next goal--backpacking in the Grand Canyon.
            </i>
          </p>
          <h3>- Vicki W.</h3>
          <hr />
          <p>
            <i>
              If you are tired of the same old exercise routines and lack proper
              motivation, then you must have a heart to heart with Karmen.  She
              possesses an infectious ability to keep you fired up during
              workout sessions. I have had back problems and have tried a
              variety of solutions. Daily physical activities were
              difficult.....I would walk into a room and immediately start
              looking for a chair. Karmen put together a series of exercises to
              give me the stamina to live a better life. Rotator Cuff injuries
              resulted in one surgery and a miserable rehabilitation. After my
              second incident, my Doctor and I decided we would fix the shoulder
              with therapy only, and Karmen was the answer. We have added 20
              yards to my tee shots. Go see Karmen.  She will make you feel
              better.
            </i>
          </p>
          <h3>- Wes S.</h3>
          <hr />
          <p>
            <i>
              I have been a client of Karmen's for over 3 years. She was the
              best choice I made that day. It is her positive encouragement when
              I say I can't and she pushes me and says "I know you can! that
              keeps me motivated to try to stay healthy and keep my body moving.
              Her big smile, endless energy and dedication to YOUR success will
              make her your best choice.
            </i>
          </p>
          <h3>- Deb N.</h3>
          <hr />
          <p>
            <i>
              I originally started training with Karmen to maintain my physical
              fitness for athletics. However, after years of working with her I
              have not only become a more physically fit individual but I have
              also had rehabilitation benefits for my various surgeries and
              sports injuries. Karmen not only helps you to look and feel more
              fit but she also helps you to become more confident in your
              abilities and in your own body. Karmen has pushed me past what I
              thought that I was capable of, but she does so in a way that I
              have never experienced any injuries from her workouts.
            </i>
          </p>
          <h3>- Maddy S.</h3>
          <hr />
          <p>
            <i>
              It is both a privilege and honor to be able to write this
              testimonial of Karmen Towne &amp; is long overdue. I have been
              working with Karmen for over 3 years and she is simply incredible!
              I first started working with Karmen when she was working at the
              YMCA. Both my primary care physician and chiropractor recommended
              I look into working with a personal trainer to address proper
              training techniques for my intense Sacroilliac (SI) joint,
              Piriformis, arthritis of my back, neck, shoulder and rotator cuff
              pain. When I had my first assessment with her, the pain was so
              severe I could barely walk one lap around the track. Working with
              Karmen has turned out to be one of the best decisions I’ve ever
              made toward my physical wellbeing and consider her an integral
              part of my health care team. When Karmen left the YMCA to pursue
              another opportunity, I followed her &amp; continue to work with
              her on a weekly basis. She has developed and tailored a program
              for me focusing on the issues I stated earlier. Today I can
              literally go down the list and check off “yes” to all the benefits
              that have come from working with Karmen. Body strength, stamina,
              endurance, better posture and balance, the list goes on. Karmen is
              knowledgeable, professional, friendly and passionate about
              wellness and fitness. She is always well prepared and keeps things
              interesting during each training session. She is also a very kind
              and caring person, who never hesitates “going the extra mile” for
              her clients to achieve their individual fitness goals. Karmen also
              provides just the right amount of motivation for me to push myself
              just a little bit more to go beyond what I think I can do. I can
              truly say I have never felt better since working with Karmen and
              at my age of 66 years, that says it all.
            </i>
          </p>
          <h3>- Peter P.</h3>
        </div>
      </section>
    </div>
  </Layout>
);

export default Testimonials;
